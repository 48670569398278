import { render, staticRenderFns } from "./Front.vue?vue&type=template&id=237c7c3c&scoped=true&"
import script from "./Front.vue?vue&type=script&lang=js&"
export * from "./Front.vue?vue&type=script&lang=js&"
import style0 from "./Front.vue?vue&type=style&index=0&id=237c7c3c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "237c7c3c",
  null
  
)

export default component.exports